import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Bar, Item } from "./ui/tabs"

export default function policyTabs({ location }) {
  return (
    <Bar>
      <Container>
        <Link to="/booking-terms-and-conditions">
          <Item selected={location.pathname.includes("booking-terms-and-conditions")}>
            <h3>BOOKING</h3>
          </Item>
        </Link>
        <Link to="/travel-credits-terms-and-conditions">
          <Item selected={location.pathname.includes("travel-credits-terms-and-conditions")}>
            <h3>TRAVEL CREDITS</h3>
          </Item>
        </Link>
        <Link to="/privacy-policy">
          <Item selected={location.pathname.includes("privacy-policy")}>
            <h3>PRIVACY POLICY</h3>{" "}
          </Item>
        </Link>
        <Link to="/terms-of-use">
          <Item selected={location.pathname.includes("terms-of-use")}>
            <h3>TERMS OF USE</h3>
          </Item>
        </Link>
        <Link to="/rewards-terms-and-conditions">
          <Item selected={location.pathname.includes("rewards-terms-and-conditions")}>
            <h3>REWARDS</h3>
          </Item>
        </Link>
        <Link to="/referral-terms-and-conditions">
          <Item selected={location.pathname.includes("referral-terms-and-conditions")}>
            <h3>REFERRALS</h3>
          </Item>
        </Link>
      </Container>
    </Bar>
  )
}

const Container = styled.div`
  width: 1170px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, auto);

  @media only screen and (max-width: 630px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`
