import styled from "styled-components"
import theme from "../theme"

const Container = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  color: #333;

  .tandc {
    margin-bottom: 2rem;
  }

  .text-container {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0px 15px 5px rgba(0, 0, 0, 0.07);
    padding: 1rem 2rem;
    margin: 2rem 0 3rem 0;

    h1 {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: 400;
    }

    p {
      color: ${theme.fontColourLight};
      line-height: 1.625;
    }

    h2 {
      margin: 2rem 0 1rem 0;
    }

    h3 {
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }

    ul {
      list-style-position: inside;
      color: ${theme.fontColourLight};
    }
  }
`

export default Container
