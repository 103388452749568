import styled from "styled-components"

const Bar = styled.div`
  width: 100%;
  display: flex;
  background: #e6e8eb;

  a {
    color: #333;
    text-decoration: none;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 626px) {
    flex-wrap: wrap;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 25%; */
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  background: #e6e8eb;
  padding: 1rem 0.5rem;
  transition: all 0.1s ease-in-out;

  ${props => props.selected && `background: #d2d4d5; `}

  &:hover {
    background: #d2d4d5;
    cursor: pointer;
  }

  h3 {
    font-weight: 200;
  }

  @media only screen and (max-width: 679px) {
    h3 {
      font-size: 0.8em;
    }
  }

  @media only screen and (max-width: 626px) {
    width: 50px;
  }

  @media only screen and (max-width: 417px) {
    width: unset;
  }
`

export { Bar, Item }
