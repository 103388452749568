import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"

import TabBar from "../components/policyTabs"
import Container from "../components/container"

export default function BookingTermsAndConditions({ location }) {
  return (
    <>
      <SEO title="Booking Terms and Conditions" />
      <Page>
        <SEO title="Booking Terms and Conditions" />
        <Container>
          <h1 className="tandc">Terms and Conditions</h1>
        </Container>
        <TabBar location={location} />
        <Container>
          <div className="text-container">
            <h1>Booking Terms and Conditions</h1>
            <p>
              Please take the time to read and understand the following Terms
              and Conditions. By making a booking, you acknowledge that you
              understand and agree to these Terms and Conditions, which include
              our Privacy Policy (the “Terms”). In these Terms, references to
              'us', 'we' or 'our' shall mean TravelbyBit Pty Ltd (ABN 88 621 527
              357). Bookings made via our website, or with our consultants in
              person, over the phone, via email or via any other method of
              communication, are all subject to these Terms and Conditions. One
              person making the booking on behalf of other travellers is taken
              to have the authority to agree to these Terms and Conditions on
              behalf of any other travellers.
            </p>

            <h2>1 Travel Requirements</h2>
            <p>
              Your travel agent can assist you with information pertaining to
              passports, visas and other travel documents you may require for
              your trip. Please be aware that, while we can assist in providing
              this information to you, it is ultimately your responsibility to
              ensure that you have the relevant documents required for your
              trip. Any penalties, fines or additional expenses incurred as a
              result of the failure to obtain these documents will be incurred
              solely by you (subject to our liability).
            </p>
            <h3>Passports</h3>
            <p>
              Travellers booking international flights must have a valid
              passport. We assume that a valid passport is held by every person
              included in the booking. If any of the travellers does not have a
              valid passport, you must inform your travel agent or contact the
              support team. Please be aware that, in addition to holding a valid
              passport, the passport is required by many countries to be valid
              for at least six (6) months from the date of return, and some
              countries may require passports to be machine-readable.
            </p>
            <h3>Visas</h3>
            <p>
              In addition to ensuring everyone included in the booking has a
              valid passport, visas and re-entry permits that satisfy
              immigration requirements and any other relevant laws must also be
              obtained.
            </p>
            <h2>2 Travel Documents</h2>
            <p>
              Travel documents include any physical or digital document that is
              intended to confirm a contract that we have arranged on your
              behalf with a travel service provider. These documents may be, but
              are not limited to, airline tickets, hotel or tour vouchers, or
              coupons for a service. Certain conditions or restrictions may be
              imposed on travel documents, such as being non-refundable or
              subject to amendment/cancellation fees. Travel documents are
              non-transferable. All airline tickets must be issued in the name
              of the passport/photo identity holder. Please ensure the details
              on your travel booking are correct and confirm the spelling of
              your name. If there is a mistake on your travel document, such as
              incorrect spelling, date or time, please contact us immediately.
              Failure to do so may result in the travel document being unusable
              or cancelled. Should circumstances arise where you require your
              travel documents earlier than two weeks before your departure,
              please let us know and a travel consultant will endeavour to
              arrange your travel documents for earlier collection.
            </p>

            <h2>3 Travel Insurance and Travel Advice</h2>
            <p>
              Since unforeseen circumstances sometimes arise when travelling,
              taking out travel insurance is strongly recommended by TravelbyBit
              and the Department of Foreign Affairs and Trade, especially for
              international travel. It is recommended that your travel insurance
              covers medical expenses, personal injury, death, loss of luggage,
              loss of money, cancellation, and personal liability insurance. If
              you require information about travel insurance, please contact
              your travel consultant.
            </p>
            <p>
              General travel advice and country-specific travel advice is
              provided by the Department of Foreign Affairs and Trade at
              www.smarttraveller.gov.au. For Australian travellers, the website
              also allows you to record your travel plans with the Department of
              Foreign Affairs and Trade to make contacting you easier in the
              event of an emergency.
            </p>
            <h2>4 Health</h2>
            <p>
              Any health requirements or precautions associated with your trip
              are your responsibility. Please ensure that you have any relevant
              vaccination documentation if you are travelling to a country
              requiring proof of vaccination. The Department of Foreign Affairs
              and Trade provides health advice for many destinations at
              www.smarttraveller.gov.au. It is also generally good practice to
              visit your general practitioner or vaccination clinic before your
              trip to see if any vaccinations are required for your travel.
            </p>

            <h2>5 Special Requirements</h2>
            <p>
              If you have any special requirements, such as dietary
              requirements, seating arrangements, disabled access, room types or
              any other special requests, please contact your travel consultant
              at your earliest convenience to allow as much time as possible for
              your requirements to be met.
            </p>
            <h2>6 Payments</h2>
            <h3>Pricing Policy</h3>
            <p>
              We reserve the right to withdraw or change prices without notice,
              including quoted prices. Prices may change due to reasons beyond
              our control which increase the cost of the product or service
              provided by a travel service provider. Your price will only be
              guaranteed once your payment has been made in full. TravelbyBit
              also reserves the right to amend any pricing errors displayed due
              to human error, computer malfunction or other reason. We will
              notify you of any error in pricing and you may elect to not
              purchase any goods or services where the price has been corrected.
            </p>
            <p>
              Please note that airline taxes are subject to change and the tax
              is finalised when your airline ticket is issued.
            </p>

            <h3>Deposits</h3>
            <p>
              For bookings made via a travel consultant, a deposit (or deposits)
              will be required. The amount of the deposit and number of deposits
              (for multiple travel service bookings) will be communicated to you
              via your travel consultant. Should an amendment or cancellation be
              made by you, your deposit(s) will be forfeited (subject to your
              rights under the Australian Consumer Law). Since final payments
              for bookings with the travel service provider are variable
              depending on the ticket, we will advise you when final payment for
              your booking is due.
            </p>

            <p style={{ textDecoration: "underline" }}>
              Credit and Debit Cards
            </p>
            <p>
              By agreeing to these terms, you authorise us to charge your
              designated credit or debit card for all fees incurred by you in
              relation to the services provided by us.
            </p>
            <p>
              Please note that a 1.5% surcharge is incurred on all credit and
              debit card payments.
            </p>
            <p>
              You acknowledge that TravelbyBit will not be liable for any
              additional costs you incur associated with exchange rates, bank
              fees, credit card fees or debit card fees.
            </p>
            <h3>Security</h3>
            <p>
              Any personal information that you submit will be protected by our
              secure SSL payment gateway server, which uses 256-bit security
              technology to protect your online order information. This
              technology encrypts all information and all personal information
              passed from you through our website.
            </p>
            <h2>7 Changes, Cancellations and Refunds</h2>
            <p>
              If you cancel or make changes to your booking, you will incur fees
              from the travel service provider. TravelbyBit can only process
              refunds to the customer upon receipt of cleared funds from our
              suppliers. Where a change or cancellation to a booking is made,
              administration processing fees may be applied on top of supplier
              related charges.
            </p>
            <h3>7. 1 Flights</h3>
            <p>
              TravelbyBit’s schedule of fees for changes and cancellations to
              flights is as follows:
            </p>

            <h4>Changes</h4>
            <p>
              Domestic changes: $22
              <br />
              International changes: $75
            </p>

            <h4>Cancellations</h4>
            <p>
              Domestic cancellations: $55
              <br />
              International cancellations: $200
            </p>

            <h3>7. 2 Flights as part of packages</h3>
            <p>
              Changes or cancellations initiated by you to flights included as
              part of a package will attract the same fees as above where that
              particular part of the package is able to be reasonably changed or
              cancelled. This may affect the status of your package and you may
              forfeit the benefit of the package, including any discount that
              was applied to the travel products as a result of purchasing the
              package.
            </p>
            <p>
              Where cancellations are made to flights as part of a package are
              initiated by any travel service provider responsible for
              delivering any part of the package, TravelbyBit will decide, at
              its discretion, whether the entire package is cancelled or whether
              that part of the package can be reasonably substituted.
            </p>
            <p>
              Cancellations initiated by travel service providers are outside of
              TravelbyBit's control and we are not liable for the consequences
              of any cancellations made by these third parties.
            </p>
            <h3>7. 3 Refunds</h3>
            <p>
              Depending on the travel supplier's cancellation policy, reason for
              cancellation, and type of booking, if your refund is approved,
              your refund will be processed depending on your method of payment.
            </p>
            <p>
              Please be aware that some bookings may be non-refundable, and in
              this instance we cannot provide you with a refund.
            </p>

            <p style={{ textDecoration: "underline" }}>
              Credit and Debit Card Payments
            </p>
            <p>
              Accepted booking cancellations paid for using credit or debit
              cards will be refunded to the original account holder/cardholder
              once the refund has been received from the supplier.
            </p>
            <p>
              To change or cancel your booking, please submit a support ticket
              via the <Link to="contact-us">contact us</Link> page or contact
              your travel agent as early as possible so that we may review your
              booking conditions and discuss your options.
            </p>
            <h2>8 Provision of Services</h2>
            <p>We guarantee that our booking services:</p>
            <ul>
              <li>will be provided with due care and skill;</li>
              <li>will be reasonably fit for the specified purpose;</li>
              <li>
                can be reasonably expected to achieve the desired result; and
              </li>
              <li>will be provided within a reasonable time.</li>
            </ul>
            <br />
            <p>
              You have rights under the Australian Consumer Law if the provision
              of our services fails to meet any of these guarantees.
            </p>
            <h2>9 Agency</h2>
            <p>
              The services we provide are in the capacity of an agent with
              permission to sell products on behalf of various transport and
              accommodation providers, as well as other service providers. We
              have an obligation to you to make travel bookings on your behalf
              and arrange contracts between you and service providers. The
              travel services are provided to you by third parties, not us as
              the agency. Though we endeavour to select reputable third parties
              to provide travel services for you, the provision of these travel
              services is outside of our control and scope of liability. Any
              booking made by us on your behalf is subject to the terms and
              conditions of the service provider(s) providing your travel
              service(s). The terms and conditions of your relevant service
              providers can be provided to you on request. If there are issues
              with the provision of your travel services, or the service
              provider is unable to provide the contracted services, unless
              there has been a fault on our part, your legal rights are against
              the specific travel service provider, not against TravelbyBit.
            </p>
            <h2>10 Liability</h2>
            <p>
              We make no representations, warranties or guarantees about the
              travel services we sell and their availability, safety, or
              reliability (except in relation to non-excludable obligations).
            </p>
            <p>
              To the maximum extent permitted by law, in no event does
              TravelbyBit nor any of its directors, employees, agents or related
              bodies corporate accept liability for any direct, indirect,
              consequential or incidental damages, delay, inconvenience or
              additional expense which may be suffered due to your use of our
              services, third party providers, force majeure or events that we
              cannot control or which could not have been prevented by
              reasonable diligence on our part.
            </p>
            <p>
              Applicable law, including the Australian Consumer Law, will limit
              our liability to the relevant remedies in the event that our
              liability cannot be excluded. These Terms and Conditions do not
              limit any of your possible rights as a consumer as set out by the
              Competition and Consumer Act 2010 (Cth), nor do these Terms and
              Conditions limit your rights under the Australian Consumer Law.
            </p>
            <h2>11 Privacy Policy</h2>
            <p>
              We collect personal information about you in order to enable you
              to use our services and for purposes otherwise set out in our
              Privacy Policy.
            </p>
            <p>
              By providing your personal information to us, you consent to the
              collection, use, storage and disclosure of that information as
              described in the Privacy Policy and these Terms. You agree that we
              may disclose your information to third parties (such as travel
              service providers) to help us deliver our services, or as required
              by law. If you do not provide this information, we may not be able
              to provide all of our services or products to you. In some cases,
              you also agree to us disclosing your personal information to
              recipients located outside of Australia (such as when
              international travel is booked by us on your behalf). Your
              personal information will usually only be disclosed to overseas
              entities in order to facilitate and administrate your booking on
              our behalf. You agree that we do not guarantee that third parties
              to which we disclose your information will be compliant with
              Australia’s privacy laws, and that we will not be liable for their
              use of your personal information.
            </p>

            <h2>12 Jurisdiction and Governing Law</h2>
            <p>
              All disputes are governed by the laws of Australia. You submit to
              the non-exclusive jurisdiction of the courts exercising
              jurisdiction in Australia.
            </p>
            <h2>13 Acknowledgement</h2>
            <p>
              You acknowledge that you are over eighteen (18) years of age and
              that you understand and agree to the above Terms and Conditions
              and Privacy Policy.
            </p>
          </div>
        </Container>
      </Page>
    </>
  )
}

const Page = styled.div`
  min-height: 100vh;

  p {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }
`
